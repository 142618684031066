import { URL_API } from "@/constants/api";
import axios from "axios";
// import url from "./api";

const access_key = process.env.VUE_APP_ACCESS_KEY;
const offline = false

const state = () => {
  return {
    user: null,
  };
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  },
};

const getters = {
  user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token,
};

const actions = {
  async login({ commit }, user) {
    if (offline) {
        if (user.email == 'admin@gm.com' && user.password == 'admin') {
          const token = 'Bearer ' + 'token'
          const user = user
          sessionStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          commit('SET_USER', { token: token, user: user })
        }
      } 
      else {
        user.access_key = access_key;
        return axios
          .post(`${URL_API.AUTH.LOGIN}`, user)
          .then((res) => res.data)
          .then((res) => {
            let user = res.data;
            let token = `Bearer ${user.token}`;

            axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("ytc_auth", JSON.stringify(user));
            commit("SET_USER", user);

            return user
          })
      }
  },

  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      localStorage.removeItem("ytc_auth");

      localStorage.clear();
      delete axios.defaults.headers.common["Authorization"];
      resolve("logged out");
    });
  },

  async get_user({ commit }) {
    const user = JSON.parse(localStorage.getItem("ytc_auth"));

    if (user) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

      commit("SET_USER", user)
    }

    return user
  },
};

export default { state, mutations, getters, actions };
