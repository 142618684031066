import axios from "axios";
import { URL_API } from "../constants/api";
// import url from "./api";

// const config = { headers: { "content-type": "application/json" } };

const state = () => ({
  data: {},
  params: {},
  dataPublish:{},
  paramsPublish: {},
  dataDraft:{},
  dataArchive:{}
});

const getters = {
  data: (state) => state.data,
  params: (state) => state.params,
  list: (state) => state.data.list || [],
  limit: (state) => state.data.limit || 10,
  page: (state) => state.data.page || 1,
  total: (state) => state.data.total || "0",
  detail: (state) => state.data.detail || {},
};

const mutations = {
  SET_DATA: (state, data) => {
    state.data = { ...state.data, ...data };
  },
  SET_PARAMS(state, data) {
    state.params = data;
  },
  SET_DETAIL: (state, data) => {
    state.data = { ...state.data, ...{ detail: data } };
  },
};

const actions = {
  // async list({ commit, getters }, params) {
  async list({ state, commit }, params ) {
    let a ={
        page: state.data.page, limit: state.data.limit, sort: 'total_unread', sort_type: params.sort_type
      }
      params = {
        ...a,
        ...state.params,
        ...params,
      };
      // console.log('params umum', params)
      state.data.list = [];
      return axios.get(URL_API.DISCUSSION.LIST_PROGRAM,{ params })
        .then((res) => res.data)
        .then((res) => {
          delete params.page;
          delete params.limit;
          commit("SET_PARAMS", params);
          commit("SET_DATA", res.data);
          return res.data;
        });
  },
};

export default { namespaced: true, state, getters, mutations, actions };
