export default { 
  Auth: require('./auth').default,
  Dashboard: require('./Dashboard').default,
  Program: require('./program').default,
  Errors: require('./errors').default,
  Programs: require('./programs').default,
  Results: require('./results').default,
  User: require('./user').default,
  Certif: require('./certificate').default,
  Import: require('./import').default,
  Discusstion: require('./discussion').default,
}
