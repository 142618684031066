const TRAINER = `trainer`;

const URL_API = {
  AUTH : {
    LOGIN: `${TRAINER}/auth/login`,
    LOGOUT: `${TRAINER}/trainer/v1/logout`,
  },
  PROGRAM: {
    LIST: `${TRAINER}/program/v1/trainer/list`,
  },
  
  MEMBER_REDEEM: {

    LIST_DETAIL_PESERTA: `${TRAINER}/users/v1/company/member-redeem/list`,

    LIST: (id) => `${TRAINER}/program/v1/trainer/member-redeem/list/${id}`,
    DETAIL: (id) => `${TRAINER}/program/v1/trainer/member-redeem/detail/${id}`,

    DETAIL_MEMBER: (id) => `${TRAINER}/program/v1/company/detail-member-redeem/${id}`,
    DETAIL_MEMBER_BY_ID: (id) => `${TRAINER}/users/v1/member/detail_by_id/${id}`,

    UPDATE_BY_ID: (id) => `${TRAINER}/users/v1/member/update_by_id/${id}`,

    FEEDBACK: `${TRAINER}/users/v1/trainer/feedback-mr`,

    EXPORT_MEMBER_REDEEM: `${TRAINER}/users/v1/export/member_redeem`,
    

    EXPORT_EPM: `${TRAINER}/users/v1/export/member_redeem_epm`,
    EXPORT: `${TRAINER}/users/v1/export/member_redeem`,
  },

  IMPORT: {
    UK: `${TRAINER}/users/v1/trainer/import-feedback-uk-mr`,
    TPM: `${TRAINER}/users/v1/trainer/import-feedback-tpm-mr`,
  },

  NOTIFICATION: {
    LIST: `${TRAINER}/notification/v1/trainer/list`,
    READ: (id) => `${TRAINER}/notification/v1/trainer/read/${id}`,
  },

  DISCUSSION: {
    LIST_PROGRAM: `${TRAINER}/discussion/v1/list-program`,
    LIST_TOPIC: `${TRAINER}/discussion/v1/list-topic`,
    LIST_ACTIVITY: `${TRAINER}/discussion/v1/list-activity`,
    PRIVATE: {
      NOTIFICATION: {
        LIST: `${TRAINER}/discussion/v1/discussion-private/list`,
        TOTAL_UNREAD: `${TRAINER}/discussion/v1/discussion-private/get-total-unread`,
        READ: (id) => `${TRAINER}/discussion/v1/discussion-private/read/${id}`,
      },
      GET_ROOM_ID: `${TRAINER}/discussion/v1/discussion-private/get-room-id`,
      DETAIL_ROOM: (roomID) => `${TRAINER}/discussion/v1/discussion-private/detail-room/${roomID}`,
      SEND_MESSAGE: (roomID) => `${TRAINER}/discussion/v1/discussion-private/room/${roomID}/send-message`,
    },
    PUBLIC: {
      LIST: `${TRAINER}/discussion/v1/list`,
      CREATE: `${TRAINER}/discussion/v1/create`,
      DETAIL_PROGRAM: (id) => `${TRAINER}/discussion/v1/detail-program/${id}`, 
    }
  }
}

export { URL_API };