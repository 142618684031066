export default [
  { title: "Dashboard", icon: "$dashboard", link: "/dashboard" , permission: 'Dashboard', children: []},
  { 
    title: "Kelola Nilai", 
    icon: "$program", 
    link: "/" , 
    permission: 'Program',
    children: [
      { title: "Daftar Program", icon: "ri-upload-cloud-2-line", link: "/program", permission: 'Program' },
      // { title: "Import Nilai UK", icon: "ri-upload-cloud-2-line", link: "/import", permission: 'Import' },
      { title: "Import Nilai TPM", icon: "ri-upload-cloud-2-line", link: "/import-feedback", permission: 'Import' },
    ]
  },
  { title: "Data Detail Test", icon: "ri-gallery-line", link: "/results", permission: 'Results', children: []},
  { 
    title: "Diskusi", 
    icon: "ri-discuss-line", 
    link: "", 
    permission: "Diskusi",
    children: [
      { title: "Diskusi Private", icon: "ri-upload-cloud-2-line", link: "/discussion/private", permission: 'Diskusi' },
      { title: "Diskusi Publik", icon: "ri-upload-cloud-2-line", link: "/discussion/public", permission: 'Diskusi' },
    ]
  },

  // { title: "Kelola Program", icon: "$program", link: "/program", permission: 'Program' },
  // { title: "Reseller", icon: "ri-user-line", link: "/daftar-reseller", permission: 'Reseller' },
  // { title: "Notifikasi", icon: "ri-chat-settings-line", link: "/notifikasi", permission: 'Notification' },
  // { title: "Presensi", icon: "ri-list-check-2", link: "/presensi", permission: 'Presensi' },
  // { title: "Import Nilai UK", icon: "ri-upload-cloud-2-line", link: "/import", permission: 'Import' },
  // { title: "Import Nilai TPM", icon: "ri-upload-cloud-2-line", link: "/import-feedback", permission: 'Import' },
];
