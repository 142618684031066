import Vue from 'vue'
import Vuex from 'vuex'
import { roomModule } from './room.module' 

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth: require('./_auth').default,
    program: require('./_program').default,
    activity: require('./_activity').default,
    media: require('./_media').default,
    user: require('./_user').default,
    notif: require('./_notif').default,
    discussion: require('./_discussion').default,
    roomModule
  }
})
