<template>
  <v-row>
    <v-col v-if="loading" cols="12" class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-col>
    <template v-else>
      <!-- <program-empty v-if="false" @create-topic="createTopic"> </program-empty> -->
      <v-col cols="12">
        <v-row align="center" class="no-gutters">
          <v-col md="6" class=" py-0">
            <h4 class="font-weight-bold">Program {{ this.titleP }} </h4>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-expansion-panels flat multiple popout>
          <v-expansion-panel :dropzone="$can('edit', 'Program')" v-for="(topic, index) in topics" :key="'A' + index" class="mb-4">
            <v-expansion-panel-header 
              disable-icon-rotate :draggable="$can('edit', 'Program')"
              @dragstart.native="handleDragStart(index)" @dragenter.native.prevent @dragover.native.prevent
              @drop.native="handleDrop(index, topics, 'topic')">
              <div class="font-weight-bold" style="font-size: 16px;">{{ index + 1 }}. {{ topic.name }}</div>
              <template slot="actions">
                <div class="d-flex align-center">
                  <div class="primary--text d-flex subtitle-2">
                    <div class="font-weight-bold mr-1">{{ topic.total_unread }}</div>
                    <div>Pesan Belum Terbaca</div>
                  </div>
                  <v-btn icon><v-icon>$expand</v-icon></v-btn>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="border-top: 1px solid lightgrey">
              <v-row>
                <v-col v-if="!!activities.find((v) => v.topic_id == topic.topic_id)" cols="12">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th v-for="(header, index) in headers" :key="index">
                          <span class="font-weight-medium">{{ header }}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="$can('edit', 'Program')">
                      <!-- v-for="(activity, index) in activities.find((v) => v.topic_id == topic.topic_id).data"  -->
                      <tr 
                        v-for="(activity, index) in activities.find((v) => v.topic_id == topic.topic_id).data" 
                        :key="index" draggable @dragstart="handleDragStart(index)"
                        @dragenter.prevent @dragover.prevent @drop="
                          handleDrop(
                            index,
                            activities.find((v) => v.topic == topic.topic_id),
                            'activity'
                          )
                          "
                          style="cursor: pointer;">
                        <td>{{ index + 1 }}</td>
                        <td>{{ activity.name }}</td>
                        <td class="text-capitalize">{{ activity.activity_type | getType }}</td>
                        <td>{{ activity.total_unread }} Pesan</td>
                        <td>
                          <v-btn
                            x-small
                            color="primary"
                            :to="editActivity(activity)">
                            Lihat
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <!-- v-for="(activity, index) in activities.find((v) => v.topic_id == topic.topic_id).data"  -->
                      <tr 
                      v-for="(activity, index) in activities.find((v) => v.topic_id == topic.topic_id)" 
                      :key="index"
                        style="cursor: pointer;">
                        <td>{{ index + 1 }}</td>
                        <!-- <td>{{ activity.name }}</td> -->
                        <!-- <td class="text-capitalize">{{ activity.activity_type | getType }}</td> -->
                        <!-- <td>{{ activity.settings.estimated_duration | getDuration }}</td> -->
                        <td>
                          <!-- <v-btn
                            x-small
                            color="primary"
                            :to="editActivity(activity)">
                            Lihat
                          </v-btn> -->
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      
      <v-dialog v-model="modalTopik.show" width="600px" persistent>
        <v-card elevation="0" rounded="lg" outlined>
          <v-card-title> <v-icon>$warning </v-icon> Hapus Topik </v-card-title>
          <v-divider class="color--text mb-4"></v-divider>
          <v-card-text>
            <template>
              <span>Apakah anda yakin ingin menghapus topik ini {{ modalTopik.name }}?</span>
            </template>
          </v-card-text>
          <v-card-actions class="pa-4" style="background: #f8f9fa">
            <v-spacer></v-spacer>
            <v-btn outlined @click="modalTopik.show = false"> Batal </v-btn>
            <v-btn @click="hapus(modalTopik.id)" color="primary" elevation="0" :loading="submit">
              Ya. Hapus!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalEdit.show" width="600px" persistent>
        <v-card elevation="0" rounded="lg" outlined>
          <v-card-title> <v-icon>$warning </v-icon> Edit Topik </v-card-title>
          <v-divider class="color--text mb-4"></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <div class="font-weight-medium mb-2">Judul Topik</div>
                <v-text-field outlined v-model="modalEdit.name"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-4" style="background: #f8f9fa">
            <v-spacer></v-spacer>
            <v-btn outlined @click="modalEdit.show = false"> Batal </v-btn>
            <v-btn @click="ubah(modalEdit.id)" color="primary" elevation="0" :loading="submit">
              Ya. Ubah!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalAktifitas.show" width="600px" persistent>
        <v-card elevation="0" rounded="lg" outlined>
          <v-card-title> <v-icon>$warning </v-icon> Hapus Aktifitas </v-card-title>
          <v-divider class="color--text mb-4"></v-divider>
          <v-card-text>
            <template>
              <span>Apakah anda yakin ingin menghapus Aktifitas ini ?</span>
            </template>
          </v-card-text>
          <v-card-actions class="pa-4" style="background: #f8f9fa">
            <v-spacer></v-spacer>
            <v-btn :disabled="modalAktifitas.loading" outlined @click="modalAktifitas.show = false">
              Batal
            </v-btn>
            <v-btn :loading="modalAktifitas.loading" @click="hapusAktivitas" color="primary" elevation="0">
              Ya. Hapus!
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar top v-model="snackbar.state" color="primary" outlined :timeout="timeout">
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </template>
  </v-row>
</template>

<script>

import { URL_API } from "@/constants/api";

import { mapState } from "vuex";
// import ProgramEmpty from "./components/Empty.vue";

export default {
  name: "program-detail",
  filters: {
    getType: (val) => (val == "membaca-e-book" ? "Membaca e-book" : val.replaceAll("_", " ")),
    getDuration: (val) => {
      const hours = (parseInt(val / 60) || 0).toFixed().padStart(2, 0);
      const minutes = (val % 60).toFixed().padStart(2, 0);
      return `${hours}:${minutes}:00`;
    },
  },
  // components: { ProgramEmpty },
  data: () => {
    return {
      modalTopik: {
        show: false,
        id: "",
      },
      modalAktifitas: {
        show: false,
        topic_id: null,
        id: null,
      },
      modalEdit: {
        show: false,
        id: "",
      },
      title_topik: "Pengenalan Program",
      topics: [
        // {
        //   "topic_id": "64cb32253bf4b692b0c7a75f",
        //   "program_id": "",
        //   "trainer_id": "tio",
        //   "name": "topik 1",
        //   "total_unread": 7,
        //   "total": 7,
        //   "created_at": "2023-12-13T18:18:27+07:00",
        //   "updated_at": "2023-12-13T18:18:27+07:00",
        //   "deleted_at": null
        // },
        // {
        //   "topic_id": "64cb32253bf4b692b0c7a75f",
        //   "program_id": "",
        //   "trainer_id": "test",
        //   "name": "topik 1",
        //   "total_unread": 3,
        //   "total": 3,
        //   "created_at": "2023-12-13T18:13:12+07:00",
        //   "updated_at": "2023-12-13T18:13:12+07:00",
        //   "deleted_at": null
        // }
      ],
      title: "",
      submit: false,
      snackbar: {
        state: false,
        text: "",
      },
      timeout: 5000,
      id: "",
      name_topic: "",
      loading: null,
      reorder: null,
      titleP: "",
    };
  },
  computed: {
    program() {
      return {
        title: "Topik",
      };
    },
    headers() {
      return ["No", "Judul Aktivitas", "Jenis Aktivitas", "Pesan Belum Terbaca", "Aksi"];
    },
    // activities() {
    //   return [
    //     {
    //       title: "Soal Test",
    //       type: "Mengerjakan Soal",
    //       duration: "00:20:00",
    //       grade: 100,
    //     },
    //     {
    //       title: "Introduction",
    //       type: "Menonton Video",
    //       duration: "00:05:00",
    //       grade: 80,
    //     },
    //   ];
    // },
    ...mapState("activity", { activities: (state) => state.list }),
  },
  created() {
    this.$emit("page-changed", 1, {
      title: this.program.title,
      link: this.$route.path,
    });
    this.fetchTopics();
    this.fetchProgram()
  },
  methods: {
    async fetchTopics() {
      this.loading = true;
      try {
        await this.axios
          .get(`${URL_API.DISCUSSION.LIST_TOPIC}`, {
            params: {
              sort: "total_unread",
              sort_type: "asc",
              limit: '-1',
              program_id: this.$route.params.id,
              q: this.name_topic,
            },
          })
          .then((res) => {
            this.topics = res.data.data.list;
          });

        await this.topics.forEach((topic) => {
          this.$store.dispatch("activity/fetch_discussion", {
            program_id: topic.program_id,
            topic_id: topic.topic_id,
            sort: "total_unread",
            sort_type: "asc",
            // params: { order_by: "created_at", dir: "asc" },
          });
        });
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    fetchProgram() {
      this.axios.get(`${URL_API.DISCUSSION.PUBLIC.DETAIL_PROGRAM(this.$route.params.id)}`).then((res) => {
        this.titleP = res.data.data.name;
        // this.$emit("page-changed", 0, {
        //   title: this.titleP,
        //   link: '/program',
        // });
        // });
        // });
        // });
        // });
        // });
        // });
        // this.$emit("page-changed", 1, {
        //   title: this.program.title,
        //   link: this.$route.path,
        // });
      });
    },
    createTopic() {
      this.$router.push({ name: "tna-create" });
    },
    editTopic(id) {
      this.$router.push({ name: "tna-edit", params: { tnaId: id } });
    },
    openDel(id, name) {
      this.modalTopik.show = true;
      this.modalTopik.id = id;
      this.modalTopik.name = name;
    },
    openEdit(id, name) {
      this.modalEdit.show = true;
      this.modalEdit.id = id;
      this.modalEdit.name = name;
    },
    editActivity(activity) {
      return {
        name: "discussion-public-list",
        params: {
          program: activity.program_id,
          topic: activity.topic_id,
          id: activity.activity_id,
          act_name: activity.name,
        },
      };
    },
    delActivity(data) {
      this.modalAktifitas.show = true;
      this.modalAktifitas.id = data.id;
      this.modalAktifitas.topic_id = data.topic.id;
    },
    async hapusAktivitas() {
      const data = this.modalAktifitas;
      this.$set(this.modalAktifitas, "loading", true);
      try {
        await this.$store.dispatch("activity/delete", {
          program: this.$route.params.id,
          topic: data.topic_id,
          id: data.id,
        });
        this.snackbar.text = `Aktivitas berhasil dihapus.`;
        this.snackbar.state = true;
        this.modalAktifitas.show = false;
        await this.topics.forEach((topic) => {
          this.$store.dispatch("activity/fetch_discussion", { program: topic.program.id, topic: topic.id });
        });
      } catch (error) {
        this.snackbar.text = `Error: ${error.message}`;
        this.snackbar.state = true;
        this.modalAktifitas.show = false;
      }
      this.$set(this.modalAktifitas, "loading", false);
    },
    ubah(id) {
      this.id = id;
      let params = {
        name: this.modalEdit.name,
        status: "publish",
        program_id: this.$route.params.id,
      };
      this.axios
        .put(`topic/v1/update/${this.id}`, params)
        .then(() => {
          this.submit = false;
          this.snackbar.text = `Topik berhasil diubah`;
          this.snackbar.state = true;
          this.modalEdit.show = false;
          this.fetchTopics();
        })
        .catch((res) => {
          this.submit = false;
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        });
    },
    hapus(id) {
      this.id = id;
      this.axios
        .delete(`topic/v1/delete/${this.id}`)
        .then(() => {
          this.submit = false;
          this.snackbar.text = `Topik berhasil dihapus`;
          this.snackbar.state = true;
          this.modalTopik.show = false;
          this.fetchTopics();
        })
        .catch((res) => {
          this.submit = false;
          if (res.response) {
            this.snackbar.text = res.response.data.message;
            this.snackbar.state = true;
          }
        });
    },
    handleDragStart(index) {
      this.reorder = index;
    },
    async handleDrop(index, model, model_name) {
      try {
        if (model_name == "activity") {
          const payload = {
            program: this.$route.params.id,
            topic: model[index].topic.id,
            data: { from: this.reorder, to: index },
          };
          await this.$store.dispatch("activity/reorder", payload);
        } else {
          const payload = {
            program_id: this.$route.params.id,
            topic_id: model[this.reorder].id,
            to: model[index].seq,
          };
          await this.axios.put("/topic/v1/reorder", payload);
        }
        const spliced = model.splice(this.reorder, 1);
        model.splice(index, 0, ...spliced);
        this.fetchTopics();
      } catch (error) {
        let message = error.message;
        if (error.response?.data?.message) {
          message = error.response?.data?.message;
        }
        this.snackbar.text = `Error: ${message}`;
        this.snackbar.state = true;
        console.error(error);
      }
      this.reorder = null;
    },
  },
};
</script>
<style></style>
