import axios from "axios";
import { URL_API } from "@/constants/api"; 

const SET_LIST = "SET_LIST";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  getters: {},
  mutations: {
    SET_LIST: (state, payload) => {
      const index = state.list.findIndex((v) => v.topic_id == payload.topic_id);
      if (index != -1) state.list.splice(index, 1);
      state.list.push(payload);
    },
  },
  actions: {
    async fetch(context, payload) {
      const response = await axios
        .get(`${URL_API.DISCUSSION.LIST_ACTIVITY}`, { params: payload })
        .then((res) => res.data);
      context.commit(SET_LIST, { topic_id: payload.topic_id, data: response.data.list });
      return response;
    },
    async fetch_discussion(context, payload) {
      const response = await axios
        .get(`${URL_API.DISCUSSION.LIST_ACTIVITY}`, { params: payload })
        .then((res) => res.data);
      context.commit(SET_LIST, { topic_id: payload.topic_id, data: response.data.list });
      return response;
    },
  },
};
